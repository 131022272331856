import './App.css';
import { Route, Routes, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import Landing from './components/Landing/Landing';
import GuestLogin from './components/GuestLogin/GuestLogin';
import SelectBarber from './components/SelectBarber/SelectBarber';
import BarberBooking from './components/BarberBooking/BarberBooking';

function App() {
  const location = useLocation();

  return (
    <div className="App">
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          <Route exact path="/" element={<Landing />} />
          <Route path="/guest-login" element={<GuestLogin />} />
          <Route path="/select-barber" element={<SelectBarber />} />
          <Route path="/barber-booking" element={<BarberBooking />} />
        </Routes>
      </AnimatePresence>
    </div>
  );
}

export default App;
