import * as React from "react";
import "./guest_style.css";
import { TextField, Button, Typography, Box} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

function InfoInput({ label, name, type }) {
    return (
        <TextField
        className="input"
          label={label}
          name={name}
          type={type}
          fullWidth
          margin="normal"
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: '10px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
              '& fieldset': {
                border: 'none',
              },
              '&.Mui-focused': {
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.3)',
              },
            },
            '& .MuiInputLabel-root': {
              '&.Mui-focused': {
                color: 'black',
              },
            },
          }}
        />
      );
}

function GuestLogin() {
  const navigate = useNavigate();
  
  const infoFields = [
    { label: "Vorname", name: "firstName", type: "text" },
    { label: "Nachname", name: "lastName", type: "text" },
    { label: "E-Mail", name: "email", type: "email" },
    { label: "Mobil", name: "phone", type: "tel" },
  ];

  const handleNext = (event) => {
    event.preventDefault();
    navigate('/select-barber');
  };

  const handleBack = () => {
    navigate('/');
  };

  return (
    <main className="main-container">
        <Box className="profileHeader">
        <ArrowBackIcon className="first-profile-item" sx={{ color: 'white' }} onClick={handleBack} />
        <h1 className="second-profile-item">
        Gast
        </h1>
        </Box>
      <section className="guest-content-container">
        <Box className="form">
          <form>
            {infoFields.map((field, index) => (
              <InfoInput key={index} label={field.label} name={field.name} type={field.type} />
            ))}
          </form>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleNext}
            sx={{
                marginTop: '20px',
                backgroundColor: 'black',
                textTransform: 'none',
                fontSize: '20px',
                borderRadius: '10px',
                '&:hover': {
                  backgroundColor: 'darkgray',
                },
              }}
            >
              Zu deinem Haarschnitt
            </Button>
          <Typography
            variant="body2"
            color="textSecondary"
            className="loginLink"
            tabIndex="0"
          >
            Bereits Kunde? Hier zum Login
          </Typography>
        </Box>
      </section>
    </main>
  );
}

export default GuestLogin;
