import * as React from "react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useLocation, useNavigate } from 'react-router-dom';
import FriseurCard from '../FriseurCard';
import { motion } from 'framer-motion';

const ServiceItem = ({ price, name, icon }) => (
  <div className="service-item">
    <div className="price">{price}</div>
    <div className="name">{name}</div>
    <img loading="lazy" src={icon} alt={`${name} icon`} className="icon" />
  </div>
);

const AdditionalService = ({ price, name }) => (
  <div className="additional-service">
    <div className="price">{price}</div>
    <div className="name">{name}</div>
    <div className="add-button">+</div>
  </div>
);

function BarberBooking() {
  const navigate = useNavigate();
  const location = useLocation();
  const { friseur } = location.state || {};

  const services = [
    { price: "20€", name: "Haarschnitt", icon: "" },
    { price: "15€", name: "Bartpflege", icon: "" },
    { price: "20€", name: "Rasur", icon: "" },
    { price: "15€", name: "Styling", icon: "" },
  ];

  const handleBack = () => {
    navigate('/select-barber');
  };

  return (
    <main className="barber-booking">
      <section className="content">
        <header className="app-header">
          <ArrowBackIcon className="first-profile-item" sx={{ color: 'white' }} onClick={handleBack} />
          <p className="price">0€</p>
        </header>
        {friseur && (
          <motion.article
            className="barber-profile"
            initial={{ y: 200, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <FriseurCard imageSrc={friseur.imageSrc} name={friseur.name} title={friseur.title} handleNext={() => {}} isSelected />
          </motion.article>
        )}
        <h2 className="services-title">Memo's Leistungen</h2>
        <div className="services-list">
          {services.map((service, index) => (
            <ServiceItem key={index} {...service} />
          ))}
        </div>
      </section>
      <footer className="booking-footer">
        <button className="book-button">Termin auswählen</button>
      </footer>
      <style jsx>{`

body{
    background-color: #fff;
}
.barber-booking {
  display: flex;
  box-sizing: border-box;
  max-width: 480px;
  width: 100%;
  flex-direction: column;
  margin: 0 auto;
  font-family: Helvetica Neue, -apple-system, Roboto, Helvetica, sans-serif;
}
.app-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.navigation {
  display: flex;
  align-items: center;
}
.nav-icon {
  aspect-ratio: 2.27;
  width: 27px;
  stroke-width: 2px;
  stroke: #fff;
  border: 2px solid #fff;
}

.content {
  background-color: #141414;
  box-sizing: border-box;
  padding: 34px 32px 78px;
  border-radius: 0px 0px 0px 80px;
}
.barber-profile {
  border-radius: 30px;
  background-color: #fff;
  display: flex;
  gap: 20px;
  padding: 16px 64px 16px 21px;
  margin-top: 35px;
}
.profile-picture {
  aspect-ratio: 0.99;
  width: 116px;
  max-width: 100%;
  border-radius: 9px;
}
.profile-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.barber-name {
  font-size: 24px;
  color: #141414;
  font-weight: 500;
  line-height: 154%;
  margin: 0;
}
.barber-title {
  color: #9c9c9c;
  position: relative;
  bottom: 10px;
  font-size: 16px;
  line-height: 231%;
  margin:0;
}
.rating {
  display: flex;
  align-items: center;
  gap: 9px;
  margin: 0;
}
.star-icon {
  aspect-ratio: 1;
  width: 22px;
}
.rating-score {
  font-size: 24px;
  color: #141414;
  font-weight: 500;
  line-height: 154%;
}
.services-title {
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  line-height: 154%;
  margin: 69px 0 27px;
}
.services-list {
  display: flex;
  flex-direction: column;
  gap: 17px;
}
.service-item, .additional-service {
  display: flex;
  align-items: center;
  gap: 20px;
}
.price {
  color: #fff;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 400;
  line-height: 185%;
  margin: 0;
}
.name {
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  line-height: 154%;
  flex-grow: 1;
}
.icon, .add-button {
  aspect-ratio: 1;
  width: 51px;
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #000;
}
.booking-footer {
  padding: 36px 0 58px;
  display: flex;
  justify-content: center;
}
.book-button {
  border-radius: 9px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25);
  background-color: #141414;
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  line-height: 154%;
  padding: 19px 53px;
  border: none;
  cursor: pointer;
}
`}</style>
    </main>
  );
}

export default BarberBooking;
