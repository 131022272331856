import React from "react";
import { Link } from 'react-router-dom';
import "./landing_style.css";

function Button({ text, className, onClick }) {
  return <button className={className} onClick={onClick}>{text}</button>;
}

function Landing() {
  return (
    <main id="landing-main" className="main-container">
      <img
        loading="lazy"
        src="Logo_white_800px.png"
        alt=""
        className="image"
      />
      <section className="content-container">
        <h1 className="title">Reserviere deinen Haarschnitt.</h1>
        <Link to="/guest-login">
          <Button text="Als Gast" className="guest-button" />
        </Link>
        {/* <Button text="Als Kunde" className="customer-button" />
        <p className="register-link">Noch kein Kunde? Hier registrieren</p> */}
      </section>
    </main>
  );
}

export default Landing;
