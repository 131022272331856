// src/components/FriseurCard.js
import * as React from "react";
import { motion } from 'framer-motion';

const FriseurCard = ({ imageSrc, name, title, handleNext }) => (
  <motion.div
    className="friseur-card"
    onClick={handleNext}
    initial={{ y: 50, opacity: 0 }}
    animate={{ y: 0, opacity: 1 }}
    exit={{ y: -50, opacity: 0 }}
    transition={{ duration: 0.5 }}
  >
    <img loading="lazy" src={imageSrc} alt={`Portrait of ${name}`} className="friseur-image" />
    <h3 className="friseur-name">{name}</h3>
    <p className="friseur-title">{title}</p>
  </motion.div>
);

export default FriseurCard;
