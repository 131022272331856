import * as React from "react";
import { useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import FriseurCard from '../FriseurCard';
import { motion, AnimatePresence, useAnimation } from 'framer-motion';

function SelectBarber({ onBack }) {
  const controls = useAnimation(); 
  const navigate = useNavigate();
  const [selectedFriseur, setSelectedFriseur] = useState(null);
  const [showServices, setShowServices] = useState(false);

  const friseure = [
    { imageSrc: "./memo.jpg", name: "Memo", title: "Unser Meister" },
  ];

  const handleNext = (friseur) => {
    setShowServices(true);
    setSelectedFriseur(friseur);
    setTimeout(() => {
    }, 500); // Adjust delay to match animation duration
    controls.start({ y: -310, borderBottomLeftRadius: '60px' });
  };

  const handleBack = () => {
    if (showServices) {
      setShowServices(false);
      setTimeout(() => {
        setSelectedFriseur(null);
      }, 500);
      controls.start({ y: 0, borderBottomLeftRadius: '0px'  });
    } else {
      navigate('/guest-login');
    }
  };

  const services = [
    { price: "20€", name: "Haarschnitt", icon: "./plus.png" },
    { price: "15€", name: "Bartpflege", icon: "./plus.png"},
    { price: "20€", name: "Rasur", icon: "./plus.png" },
    { price: "15€", name: "Styling", icon: "./plus.png" },
  ];

  return (
    <>
      <main className="app-container">
        <header className="app-header">
          <ArrowBackIcon className="first-profile-item" sx={{ color: 'black' }} onClick={handleBack} />
          <h1 className="main-title">Suche dir deinen Friseur aus.</h1>
          <img loading="lazy" src="./Rasiermesser_icon.jpg" alt="Decorative hairstyle illustration" className="header-image" />
        </header>
        <motion.div
          className="motion-container"
          initial={{ y: 0, borderBottomLeftRadius: '0px'  }}
          animate={controls}
          transition={{ duration: 0.5 }}
        >
        <section className="friseure-section">
          <h2 className="section-title">Unsere Friseure</h2>
          <div className="friseure-list">
            {friseure.map((friseur, index) => (
              <FriseurCard 
                key={index} 
                {...friseur} 
                handleNext={() => handleNext(friseur)}
              />
            ))}
          </div>
          <AnimatePresence>
            {showServices && (
              <motion.div
                className="services-list"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5, delay: 0.5 }}
              >
                <h2 className="services-title">Leistungen</h2>
                {services.map((service, index) => (
                  <div className="service-item" key={index}>
                    <div className="price">{service.price}</div>
                    <div className="name">{service.name}</div>
                    <img loading="lazy" src={service.icon} alt={`${service.name} icon`} className="icon" />
                  </div>
                ))}
              </motion.div>
            )}
          </AnimatePresence>
        </section>
             { showServices && (
              <div className="book-button-container">
                <button className="book-button">Termin auswählen</button>
              </div>
             )} 
        </motion.div>
      </main>
      <style jsx>{`
        .motion-container {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        border-radius: 60px 0 0 0;
        background-color: #141414;
      }
        .book-button-container{
        height: 0px;
        position: relative;
        bottom: -70px;
        padding: 19px 50px;
        }

        .book-button {
          background-color: #141414;
          color: #fff;
          font:25px/37px "Helvetica Neue", -apple-system, Roboto, Helvetica, sans-serif;
          border-radius: 9px;
          box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
          border: none;
          justify-content: center;
          width: 100%;
          margin-bottom: 50px;
        }

        .book-button:hover {
        cursor: pointer;
      }
        .app-container {
          background-color: #fff;
          display: flex;
          box-sizing: border-box;
          max-width: 480px;
          box-sizing: border-box;
          padding-top: 34px;
          flex-direction: column;
          font-weight: 500;
          margin: 0 auto;
          height: 100vh;
        }
        .app-header {
          display: flex;
          box-sizing: border-box;
          width: 100%;
          flex-direction: column;
          align-items: flex-start;
          font-size: 40px;
          color: #000;
          text-align: center;
          line-height: 92.5%;
          padding: 0 32px;
        }
        .search-icon {
          aspect-ratio: 2.27;
          object-fit: auto;
          object-position: center;
          width: 27px;
          stroke-width: 2px;
          stroke: #141414;
          border: 2px solid #141414;
        }
        .main-title {
          font: bold 40px/37px "Helvetica Neue", -apple-system, Roboto, Helvetica, sans-serif;
        }
        .header-image {
          aspect-ratio: 0.93;
          object-fit: auto;
          object-position: center;
          width: 144px;
          align-self: center;
          margin-bottom: 48px;
          max-width: 100%;
        }
        .first-profile-item{
          margin-left: 10px;
          flex: 0 0 auto;
        }
        .friseure-section {
          display: flex;
          box-sizing: border-box;
          width: 100%;
          flex-direction: column;
          align-items: flex-start;
          padding: 35px 50px;
          flex-grow: 1;
        }
        .section-title {
          color: #fff;
          text-align: center;
          font: 24px/154% Helvetica Neue, -apple-system, Roboto, Helvetica, sans-serif;
          margin: 0;
        }
        .friseure-list {
          display: flex;
          flex-direction: column;
          width: 100%;
        }
        .friseur-card {
          border-radius: 30px;
          background-color: #fff;
          display: flex;
          margin-top: 10px;
          flex-direction: column;
          align-items: flex-start;
          padding: 21px 80px 0px 24px;
        }
        
        .friseur-card:hover {
          cursor: pointer;
        }
        .friseur-image {
          aspect-ratio: 0.99;
          object-fit: auto;
          object-position: center;
          width: 116px;
          max-width: 100%;
          border-radius: 10px;
        }
        .friseur-name {
          color: #141414;
          margin:0;
          font: 24px/154% Helvetica Neue, -apple-system, Roboto, Helvetica, sans-serif;
        }
        .friseur-title {
          color: #9c9c9c;
          position: relative;
          bottom: 15px;
          margin: 0px;
          font: 16px/231% Helvetica Neue, -apple-system, Roboto, Helvetica, sans-serif;
        }
          .services-title {
          color: #fff;
          font-size: 24px;
          font-weight: 500;
          line-height: 154%;
          margin: 69px 0 27px;
        }
        .services-list {
        width: 100%;
          display: flex;
          flex-direction: column;
          gap: 17px;
        }
        .service-item, .additional-service {
          display: flex;
          align-items: center;
          gap: 20px;
        }
        .price {
          color: #fff;
          align-items: center;
          justify-content: center;
          font-size: 20px;
          font-weight: 400;
          line-height: 185%;
          margin: 0;
        }
        .name {
          color: #fff;
          font-size: 24px;
          font-weight: 500;
          line-height: 154%;
          flex-grow: 1;
        }
        .icon, .add-button {
          padding: 9px;
          box-sizing: border-box;
          aspect-ratio: 1;
          width: 44px;
          border-radius: 10px;
          background-color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 20px;
          color: #000;
        }
      `}</style>
    </>
  );
}

export default SelectBarber;

